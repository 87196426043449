
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import {IAdminRole, IAdminRoleDetail, permissionTypes} from "@/models/menu_model";
import ApiAdmin from "@/request/apis/api_admin";
import {IAdminInfo} from "@/models/user_models";

interface IState {
  loading: boolean;
  allRoles: IAdminRole[]
}

// 新增编辑文章
export default defineComponent({
  name: "AdminSet",
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<IAdminInfo> = reactive({
      "name": "",
      "account": "",
      "password":"",
      "disable":0,
      "role_ids": undefined,
      mobile: "",
      rePassword: "",
      avatar: "",
    })
    const repasswordAuth = (rule:any, value:any, callback:any) => {
      if (!value) return Promise.resolve();
      if (value !== formState.password) {
        return Promise.reject("两次输入密码不一致")
      }
      return Promise.resolve();
    };
    const rules = {
      account: [{required: true, message: "请输入账号"},],
      password: [{required: false, message: "请输入登录密码"},],
      rePassword: [{required: false, message: "请再次输入登录密码"}, {validator: repasswordAuth, trigger: 'blur',}],
      name: [{required: true, message: "请输入真实姓名"},],
      role_ids: [{required: true, message: "请选择管理员角色", type: "array"},],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)};
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: string) => {
      formState.avatar = url
    }

    const state: IState = reactive({
      loading: false,
      allRoles: []
    })

    // 获取全部菜单
    const getAllRole = async () => {
      state.loading = true
      const res = await ApiAdmin.getRoleList({page: 1, limit: 1000});
      state.loading = false
      if (res && res.error_code === 0) {
        state.allRoles = res.data?.lists || [];
      }
    };

    // 获取详情
    const getDetail = async () => {
      const {id, data} = route.query;
      if (id && data) {
        editId = id
        let datas = JSON.parse(data as string) as IAdminInfo;
        formState.id = parseInt(id as string);
        formState.password = "";
        formState.name = datas.name;
        formState.account = datas.account;
        formState.mobile = datas.mobile;
        formState.avatar = datas.avatar;
        formState.role_id = datas.role_id;
        formState.root = datas.root;
        formState.type = datas.type;
        formState.disable = datas.disable;
        formState.role = datas.role;
        formState.create_time = datas.create_time;
        formState.update_time = datas.update_time;
        formState.login_time = datas.login_time;
        formState.login_ip = datas.login_ip;
        formState.role_ids = datas.roles?.map(item => {return (item.role as IAdminRoleDetail).id}) as number[] || [];
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      console.log(params)
      const res = await ApiAdmin.addAdmin(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const getImageUrl = (url:string) => {
      if (!url || !url.length) return null;
      if (url.indexOf("http") >= 0) return url;
      return "http://jjyyimage.ynjjyy.cn/" + url;
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiAdmin.updateAdminInfo(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getAllRole();
      getDetail()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      goBack,
      permissionTypes,
      ...toRefs(state),
      editorComponentRef,
      getImageUrl,
      onImageChange
    }
  },
})
